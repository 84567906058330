<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Bowser from "bowser";

export default {
  data() {
    return {};
  },
  mounted() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.isBrowser("firefox", true)) {
      this.$router.push({ name: "ext-firefox" });
    } else if (browser.isBrowser("edge", true)) {
      this.$router.push({ name: "ext-edge" });
    } else {
      this.$router.push({ name: "ext-chrome" });
    }
  },
  methods: {}
};
</script>
